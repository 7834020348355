import { Component, OnInit } from '@angular/core'
import { MediaModalService } from '../../services/media-modal.service'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AppActions, fromAppSelector } from 'src/app/core/store'
import { AppState } from 'src/app/core/store/app.reducers'
import { CommonModule } from '@angular/common'
import { NgxDocViewerModule } from 'ngx-doc-viewer'

@Component({
  selector: 'app-media-modal',
  templateUrl: './media-modal.component.html',
  styleUrls: ['./media-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, NgxDocViewerModule]
})
export class MediaModalComponent implements OnInit {
  constructor(
    private modalService: MediaModalService,
    private store: Store<AppState>
  ) {}

  display$!: Observable<'open' | 'close'>

  media$ = this.store.select(fromAppSelector.selectedMedia)

  ngOnInit() {
    this.display$ = this.modalService.watch()
  }

  close() {
    this.modalService.close()
    this.store.dispatch(AppActions.setMedia({ media: null }))
  }
}
