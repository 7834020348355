import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router'
import { select, Store } from '@ngrx/store'
import { Observable, tap } from 'rxjs'
import { Injectable } from '@angular/core'
import { fromAppSelector } from '../store'
import { AppState } from '../store/app.reducers'
@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<AppState>
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select(fromAppSelector.isLoggedIn),
      tap((loggedIn) => {
        if (loggedIn) {
          return true
        }
        this.router.navigateByUrl('/auth/sign-in')
        return false
      })
    )
  }
}
