import { Observable, Subject } from 'rxjs'
import { filter } from 'rxjs/operators'
import { Injectable, NgZone, PLATFORM_ID, Inject } from '@angular/core'
import { runInZone } from './run-in-zone'
import { isPlatformBrowser } from '@angular/common'

interface BroadcastMessage {
  type: string
  payload: any
}
@Injectable({
  providedIn: 'root'
})
export class BroadcastService {
  private broadcastChannel: BroadcastChannel | undefined
  private onMessage = new Subject<any>()

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private ngZone: NgZone
  ) {
    if (isPlatformBrowser(this.platformId)) {
      // Only create BroadcastChannel in the browser environment
      this.broadcastChannel = new BroadcastChannel('zabibu-crm-channel')
      this.broadcastChannel.onmessage = (message) =>
        this.ngZone.run(() => this.onMessage.next(message.data))
    }
  }

  publish(message: BroadcastMessage): void {
    if (this.broadcastChannel) {
      this.broadcastChannel.postMessage(message)
    }
  }

  messagesOfType(type: string): Observable<BroadcastMessage> {
    return this.onMessage.pipe(filter((message) => message.type === type))
  }
}
