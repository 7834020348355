import { Component, Input, OnInit } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { CommonModule } from '@angular/common'
import { select, Store } from '@ngrx/store'
import { AppState } from '../../core/store/app.reducers'
import { fromAppSelector } from '../../core/store'

@Component({
  selector: 'app-input',
  standalone: true,
  templateUrl: './input.component.html',
  imports: [ReactiveFormsModule, TranslateModule, CommonModule],
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    private store: Store<AppState>
  ) {}

  @Input() control: FormControl = new FormControl()
  @Input() type: string = ''
  @Input() placeholder: string = ''
  @Input() required: boolean = false
  @Input() readonly: boolean = false
  @Input() darker = false

  @Input() id: string = ''
  isDarkTheme = false

  ngOnInit() {
    this.store.pipe(select(fromAppSelector.currentTheme)).subscribe((theme) => {
      this.isDarkTheme = theme === 'dark'
    })
  }

  displayErrors() {
    const { dirty, touched, errors } = this.control
    return dirty && touched && errors
  }
}
