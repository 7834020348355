import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import {
  IAcceptInvitation,
  IInvitation,
  IResult
} from '../@types/invitation.types'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class InvitationService {
  constructor(private http: HttpClient) {}

  acceptInvitation(acceptInvitation: IAcceptInvitation): Observable<IResult> {
    return this.http.patch<IResult>(
      `${environment.BASE_URL}/invitations/accept`,
      acceptInvitation
    )
  }

  sendInvitation(invitation: IInvitation) {
    return this.http.post(
      `${environment.BASE_URL}/invitations/send`,
      invitation
    )
  }
}
