import { FakeSubService } from 'src/app/modules/services/@types/faks-sub-service'
import { Service } from 'src/generated-types'

export function sortByServiceProviderFirstName(services: Service[]) {
  const servicesCopy = [...services]
  return servicesCopy.sort((a, b) => {
    const nameA = a.serviceProvider?.firstName?.toUpperCase() || ''
    const nameB = b.serviceProvider?.firstName?.toUpperCase() || ''

    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }

    // names must be equal
    return 0
  })
}

export function sortFakeProviderByFirstName(services: FakeSubService[]) {
  const servicesCopy = [...services]
  return servicesCopy.sort((a, b) => {
    const nameA = a.serviceProvider?.firstName?.toUpperCase() || ''
    const nameB = b.serviceProvider?.firstName?.toUpperCase() || ''

    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }

    // names must be equal
    return 0
  })
}

export function groupServicesByProvider(services: Service[]): {
  [key: string]: Service[]
} {
  return services.reduce(
    (groups, service) => {
      const providerId = service.serviceProvider!.id
      if (!groups[providerId]) {
        groups[providerId] = []
      }
      groups[providerId].push(service)
      return groups
    },
    {} as { [key: string]: Service[] }
  )
}
