<ng-container *ngIf="display$ | async as display">
  <section
    class="container-modal"
    [class.open]="display === 'open'"
    (click)="close()"
  >
    <div class="close" (click)="close()">
      <i class="fa-solid fa-close"></i>
    </div>
    <div
      (click)="$event.stopPropagation()"
      class="modal"
      *ngIf="media$ | async as media"
      [class.fullHeight]="media.mediaType === 'Document'"
    >
      <video *ngIf="media.mediaType === 'Video'" autoplay controls>
        <source [src]="media.url" />
        Video not supported
      </video>
      <img *ngIf="media.mediaType === 'Image'" [src]="media.url" />
      <ngx-doc-viewer
        *ngIf="media.mediaType === 'Document'"
        [url]="media.url"
        viewer="url"
      >
      </ngx-doc-viewer>
    </div>
  </section>
</ng-container>
