import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'
import { NgModule, PLATFORM_ID } from '@angular/core'
import { ApolloClientOptions, InMemoryCache, split } from '@apollo/client/core'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { environment } from 'src/environments/environment'
import { isPlatformServer } from '@angular/common'

import WebSocket from 'ws'

let WebSocketImpl: typeof WebSocket
if (typeof window === 'undefined') {
  WebSocketImpl = WebSocket
}

export function createApollo(
  httpLink: HttpLink,
  platformId: Object
): ApolloClientOptions<any> {
  const http = httpLink.create({
    uri: `${environment.BASE_URL}/graphql`
  })

  let ws: GraphQLWsLink
  if (isPlatformServer(platformId)) {
    ws = new GraphQLWsLink(
      createClient({
        url: `wss://${environment.WSS_URI}`,
        webSocketImpl: WebSocketImpl
      })
    )
  } else {
    ws = new GraphQLWsLink(
      createClient({
        url: `wss://${environment.WSS_URI}`
      })
    )
  }

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    ws,
    http
  )

  return {
    link,
    cache: new InMemoryCache(),
    ssrMode: true,
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache'
      },
      mutate: {
        fetchPolicy: 'no-cache'
      }
    }
  }
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, PLATFORM_ID]
    }
  ]
})
export class GraphQLModule {}
