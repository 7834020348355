import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { AuthenticationGuard } from './core/guards/authentication.guard'

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/authentication/authentication.module').then(
        (module) => module.AuthenticationModule
      )
  },

  {
    path: 'accept-invitation',
    loadChildren: () =>
      import('./modules/invitations/invitations.module').then(
        (module) => module.InvitationsModule
      )
  },
  {
    path: '',
    canActivate: [AuthenticationGuard],
    loadChildren: () =>
      import('./modules/home/home.module').then((module) => module.HomeModule)
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
