import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { concatMap, tap } from 'rxjs'
import {
  CompleteServiceProviderProfileGQL,
  UpdateServiceProviderDto
} from '../../../../../generated-types'
import { passwordRegex } from '../../../../core/helpers/password-reg'
import { ToastHelper } from '../../../../core/helpers/toast'
import { CreateUserInfo } from '../../@types/create-user.interface'
import { AppState, sharedFormData } from '../../../../core/store/app.reducers'
import { AppActions } from '../../../../core/store'
import { IAcceptInvitation } from '../../../invitations/@types/invitation.types'
import { LocalStorageService } from 'src/app/core/services/local-storage.service'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { AuthenticationService } from '../../services/authentication.service'
import { InputComponent } from '../../../../shared/input/input.component'
import { CommonModule } from '@angular/common'
import { MatTooltipModule } from '@angular/material/tooltip'
import { InvitationService } from 'src/app/modules/invitations/services/invitation.service'
import { emailRegex } from 'src/app/core/helpers/email-reg'

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss'],
  standalone: true,
  imports: [
    InputComponent,
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    MatTooltipModule
  ]
})
export class SignUpFormComponent implements OnInit {
  @Input() isSignup: boolean = true
  @Input() firstName: string = ''
  @Input() lastName: string = ''
  @Input() middleName: string = ''
  @Input() email: string = ''
  @Input() id!: string
  isLoading = false
  @Output() loadingChange = new EventEmitter<boolean>()
  fb = new FormBuilder()
  errorMessage = ''

  togglePasswordField = false

  acceptInvitationDto: IAcceptInvitation = {
    token: ''
  }

  // SearchCountryField = SearchCountryField
  // CountryISO = CountryISO

  // PhoneNumberFormat = PhoneNumberFormat

  signupForm = this.fb.group(
    {
      email: new FormControl(this.email, [
        Validators.required,
        Validators.email,
        Validators.pattern(emailRegex)
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(passwordRegex)
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(passwordRegex)
      ]),
      // phone: new FormControl(''),
      middleName: new FormControl(this.middleName, [Validators.minLength(2)]),
      firstName: new FormControl(this.firstName, [
        Validators.required,
        Validators.minLength(2)
      ]),
      lastName: new FormControl(this.lastName, [
        Validators.required,
        Validators.minLength(2)
      ])
    },
    { updateOn: 'change' }
  )

  // eslint-disable-next-line max-params
  constructor(
    public translate: TranslateService,
    private toastHelper: ToastHelper,
    private authenticationService: AuthenticationService,
    private completeServiceProviderProfileGQL: CompleteServiceProviderProfileGQL,
    private acceptInvitationService: InvitationService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private store: Store<AppState>,
    private errorService: ErrorsService
  ) {}

  ngOnInit() {
    if (!this.isSignup) {
      this.signupForm.controls.email.setValue(this.email)
      this.signupForm.controls.firstName.setValue(this.firstName)
      this.signupForm.controls.lastName.setValue(this.lastName)
      this.signupForm.controls.middleName.setValue(this.middleName)
    }
  }

  handleNavigation(path: string) {
    return this.router.navigate([path])
  }

  onSubmit() {
    if (this.signupForm.valid) {
      const { email, password, confirmPassword, ...others } =
        this.signupForm.value

      if (password !== confirmPassword) {
        this.toastHelper.warningToast(
          '',
          this.translate.instant('signup.errors.password.description')
        )
        return
      }
      this.isLoading = true
      this.loadingChange.emit(this.isLoading)
      const newUser = {
        email,
        password,
        ...others
      } as CreateUserInfo

      const updatedServiceProvider = {
        email,
        password,
        id: Number(this.id),
        ...others
      } as unknown as UpdateServiceProviderDto

      const data: sharedFormData = {
        isWaitingValidation: false,
        emailTobeVerified: '',
        isLoading: true
      }

      this.isSignup
        ? this.authenticationService
            .signUp(newUser)
            .pipe(
              tap((_) => {
                const data: sharedFormData = {
                  isWaitingValidation: true,
                  emailTobeVerified: email!,
                  isLoading: false
                }
              })
            )
            .subscribe({
              next: (_) => {
                this.toastHelper.successToast(
                  '',
                  this.translate.instant('signup.success.description')
                )
                this.router.navigateByUrl('/')
                this.isLoading = false
                this.loadingChange.emit(this.isLoading)
              },
              error: (err) => {
                const data: sharedFormData = {
                  isWaitingValidation: false,
                  emailTobeVerified: '',
                  isLoading: false
                }
                this.errorService.authError(err.error.message)
                this.isLoading = false
                this.loadingChange.emit(this.isLoading)
              }
            })
        : this.completeServiceProviderProfileGQL
            .mutate({
              updateServiceProviderInput: updatedServiceProvider
            })
            .pipe(
              tap(() => {
                this.acceptInvitation()
                this.loginUser(email!, password!)
              })
            )
            .subscribe({
              next: (_) => {
                const data: sharedFormData = {
                  isWaitingValidation: false,
                  emailTobeVerified: '',
                  isLoading: false
                }
                this.toastHelper.successToast(
                  '',
                  this.translate.instant('signup.success.completeProfile')
                )
                this.isLoading = false
                this.loadingChange.emit(this.isLoading)
              },
              error: (err) => {
                const data: sharedFormData = {
                  isWaitingValidation: false,
                  emailTobeVerified: '',
                  isLoading: false
                }
                this.errorService.authError(err.error.message)
                this.isLoading = false
                this.loadingChange.emit(this.isLoading)
              }
            })
    }
  }

  loginUser(email: string, password: string) {
    return this.authenticationService
      .signIn(email, password)
      .pipe(
        tap((res) => {
          this.localStorageService.setRefreshToken(res.refreshToken)
          this.localStorageService.setAccessToken(res.accessToken)
          this.router.navigateByUrl('/')
        }),
        concatMap(() => this.authenticationService.fetchUserInfo())
      )
      .subscribe()
  }

  acceptInvitation() {
    return this.acceptInvitationService
      .acceptInvitation(this.acceptInvitationDto)
      .pipe()
      .subscribe()
  }

  showPassword() {
    return (this.togglePasswordField = !this.togglePasswordField)
  }

  resetForm() {
    this.errorMessage = ''
    this.signupForm.reset()
  }
}
