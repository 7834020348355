<div class="container">
  <div class="logo">
    <img
      src="https://res.cloudinary.com/dghlwp1oo/image/upload/v1723485485/logo-white_tnn53p.png"
      loading="lazy"
      alt="ZCRM Logo"
    />
  </div>
  <div class="text">
    <p>
      {{ 'auth.slogan' | translate }}
    </p>
  </div>
  <div class="footer">
    <h5>
      {{ 'auth.footer.text' | translate }}
      <a href="https://zabibu.co" target="_blank"
        >{{ 'auth.footer.link' | translate }}
      </a>
    </h5>
    <p class="copyright">&copy; Copyright - {{ currentYear }}</p>
  </div>
</div>
