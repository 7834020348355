import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { ToastHelper } from '../../../../core/helpers/toast'
import { select, Store } from '@ngrx/store'
import { AppState } from '../../../../core/store/app.reducers'
import { fromAppSelector } from '../../../../core/store'
import { IAcceptInvitation, IResult } from '../../@types/invitation.types'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { InvitationService } from '../../services/invitation.service'
@Component({
  selector: 'app-accept-invitation',
  templateUrl: './accept-invitation.component.html',
  styleUrls: ['./accept-invitation.component.scss']
})
export class AcceptInvitationComponent implements OnInit {
  widget = {
    error: false,
    hasAccountSet: false
  }

  acceptInvitationDto: IAcceptInvitation = {
    token: ''
  }

  result!: IResult

  isLoading = false

  // eslint-disable-next-line max-params
  constructor(
    private acceptInvitationService: InvitationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private toastHelper: ToastHelper,
    private store: Store<AppState>,
    private errorService: ErrorsService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.acceptInvitationDto.token = params['token']
    })

    if (this.acceptInvitationDto.token !== '') {
      this.isLoading = true
      this.acceptInvitationService
        .acceptInvitation(this.acceptInvitationDto)
        .pipe()
        .subscribe({
          next: (res) => {
            this.isLoading = false
            this.result = res
            if (this.result.hasPassword || this.result.googleId) {
              this.widget.hasAccountSet = true
              this.toastHelper.successToast(
                '',
                this.translate.instant('acceptInvitation.accepted.existingUser')
              )
            } else {
              this.toastHelper.infoToast(
                '',
                this.translate.instant(
                  'acceptInvitation.accepted.unExistingUser'
                )
              )
            }
          },
          error: (error) => {
            this.isLoading = false
            this.widget.error = true
            this.errorService.authError(error.error.message)
          }
        })
    }
  }

  handleNavigation(path: string) {
    return this.router.navigateByUrl(path)
  }
}
