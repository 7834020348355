import { User as CurrentUser } from '../../modules/authentication/@types/user.interface'
import { TokenResponse } from '../../modules/authentication/services/authentication.service'
import { createReducer, on } from '@ngrx/store'
import { AppActions } from './index'
import {
  Client,
  Document,
  Service,
  ServiceDate,
  User
} from 'src/generated-types'
import {
  ServiceProviderRate,
  ServiceType
} from 'src/app/modules/services/@types/service-type'
import { ConversionReturnType } from 'src/app/modules/services/@types/conversion'
import {
  AttachmentDraft,
  FakeSubServiceDraft,
  ServiceDraft
} from 'src/app/modules/services/@types/service-draft'
import { Theme } from 'src/app/shared/@types/shared-types'
import { FakeSubService } from 'src/app/modules/services/@types/faks-sub-service'
import {
  sortByServiceProviderFirstName,
  sortFakeProviderByFirstName
} from '../helpers/sort-provider-by-firstname'
export const appSelector = 'root'

export interface sharedFormData {
  isWaitingValidation: boolean
  isLoading: boolean
  emailTobeVerified: string
}

export enum Rate {
  DAILY = 'dailyRate',
  HOURLY = 'hourlyRate',
  SERVICE = 'serviceRate'
}

export enum ServiceView {
  CALENDAR = 'calendar',
  LIST = 'list',
  CHART = 'chart'
}

export interface SelectedService {
  id: number | null
  uuid: string | null
}

export interface BasicServiceInfo {
  client: {
    id: number
    companyName: string
  }
  isFullTime: boolean
  designation: string
  description: string
  parentAttachments?: Document[]
  serviceType?: { id: number; name: string }
}

export interface BillingServiceInfo {
  currency: string
  billingType: Rate
  inviteServiceProvider: boolean
  isGlobalBilling: boolean
  parentServiceDatesDefined: boolean
  serviceRate: number | null
  serviceProviderRate?: number | null
  numberOfHours: number | null
  convertedRate?: ConversionReturnType | null
  defaultRate?: ServiceProviderRate
  wasRateDivided?: boolean
  billingPhaseFrequencyType: string
  billingDate?: string
  hasSubServices: boolean
  disableSaveRateModal: boolean
}

export interface DatesServiceInfo {
  excludeWeekends: boolean
  uniqueChildServiceDates: boolean
  dates: string[]
  range: {
    startDate: Date
    endDate: Date
  }
}

export interface ServiceTimelineData {
  dates: ServiceDate[]
  highted: {
    provider: User
    dates: ServiceDate[]
  }[]
  currentMonth: number
  currentYear: number
  status: string
  isFromCalendar: boolean
}

export interface AppState {
  currentUser: CurrentUser | null
  tokens: TokenResponse | null
  month: number | null
  year: number | null
  theme: string
  leaveEditing: boolean
  shouldRefreshServices: boolean
  createdServiceInfo: Service | null
  showAmount: boolean
  selectedMedia: { url: string; mediaType: string } | null
  removePastDates: boolean
  serviceTimelineData: ServiceTimelineData | null
  serviceDraft: ServiceDraft | null
  client: Client | null
  serviceType: ServiceType | null
  serviceProvider: User | null
  fakeSubServices: FakeSubService[]
  basicInfo: BasicServiceInfo | null
  billingInfo: BillingServiceInfo | null
  datesInfo: DatesServiceInfo | null
  attachments: {
    childAttachments: AttachmentDraft[]
    parentAttachments: AttachmentDraft[]
  } | null
  selectedFakeSubService: FakeSubServiceDraft | null
}

export const initialAppState: AppState = {
  currentUser: null,
  tokens: null,
  month: null,
  year: null,
  theme: Theme.Light,
  leaveEditing: false,
  shouldRefreshServices: false,
  createdServiceInfo: null,
  showAmount: true,
  selectedMedia: null,
  removePastDates: true,
  serviceTimelineData: null,
  serviceDraft: null,
  client: null,
  serviceType: null,
  serviceProvider: null,
  fakeSubServices: [],
  basicInfo: null,
  billingInfo: null,
  datesInfo: null,
  attachments: null,
  selectedFakeSubService: null
}

export const appReducer = createReducer(
  initialAppState,
  on(AppActions.login, (state, action) => {
    return {
      ...state,
      currentUser: action.currentUser
    }
  }),

  on(AppActions.logout, (state, action) => {
    return {
      ...state,
      currentUser: null
    }
  }),

  on(AppActions.tokens, (state, action) => {
    return {
      ...state,
      tokens: action.tokens
    }
  }),

  on(AppActions.changeMonth, (state, action) => {
    return {
      ...state,
      month: action.month
    }
  }),

  on(AppActions.changeYear, (state, action) => {
    return {
      ...state,
      year: action.year
    }
  }),

  on(AppActions.switchTheme, (state, action) => {
    return {
      ...state,
      theme: action.theme
    }
  }),

  on(AppActions.leaveEditing, (state, action) => {
    return {
      ...state,
      leaveEditing: action.leaveEditing
    }
  }),

  on(AppActions.shouldRefreshServices, (state, action) => {
    const { shouldRefreshServices, ...others } = state

    return {
      ...others,
      shouldRefreshServices: action.shouldRefreshServices
    }
  }),

  on(AppActions.setCurrentService, (state, action) => {
    const { createdServiceInfo, ...others } = state

    return {
      ...others,
      createdServiceInfo: action.service
    }
  }),

  on(AppActions.showAmount, (state, action) => {
    const { showAmount, ...others } = state
    return {
      ...others,
      showAmount: !showAmount
    }
  }),

  on(AppActions.setMedia, (state, action) => {
    const { selectedMedia, ...others } = state
    return {
      ...others,
      selectedMedia: action.media
    }
  }),

  on(AppActions.removePastDates, (state, action) => {
    const { removePastDates, ...others } = state
    return {
      ...others,
      removePastDates: action.removePastDates
    }
  }),

  on(AppActions.setServiceTimelineData, (state, action) => {
    const { serviceTimelineData, ...others } = state

    return {
      ...others,
      serviceTimelineData: action.data
    }
  }),

  on(AppActions.setServiceDraft, (state, action) => {
    const { serviceDraft, ...others } = state

    return {
      ...others,
      serviceDraft: action.draft
    }
  }),

  on(AppActions.setClient, (state, action) => {
    return {
      ...state,
      client: action.client
    }
  }),

  on(AppActions.setSelectedServiceType, (state, action) => {
    return {
      ...state,
      serviceType: action.serviceType
    }
  }),

  on(AppActions.setBasicInfo, (state, action) => {
    return {
      ...state,
      basicInfo: action.info
    }
  }),

  on(AppActions.setBillingInfo, (state, action) => {
    return {
      ...state,
      billingInfo: action.info
    }
  }),

  on(AppActions.setDatesInfo, (state, action) => {
    return {
      ...state,
      datesInfo: action.info
    }
  }),

  on(AppActions.setServiceProvider, (state, action) => {
    return {
      ...state,
      serviceProvider: action.serviceProvider
    }
  }),

  on(AppActions.addFakeSubService, (state, action) => {
    const { fakeSubServices, ...others } = state
    const { fakeService } = action

    // Check if the provider already exists in the array
    const providerExists = fakeSubServices.some(
      (service) => service.serviceProvider.id === fakeService.serviceProvider.id
    )

    // If provider exists, remove it; otherwise, add it
    const updatedFakeSubServices = providerExists
      ? fakeSubServices.filter(
          (service) =>
            service.serviceProvider.id !== fakeService.serviceProvider.id
        )
      : [...fakeSubServices, fakeService]

    return {
      ...others,
      fakeSubServices: sortFakeProviderByFirstName(updatedFakeSubServices)
    }
  }),

  on(AppActions.resetFakeServices, (state, action) => {
    const { fakeSubServices, ...others } = state
    return {
      ...others,
      fakeSubServices: []
    }
  }),

  on(AppActions.setSelectedFakeSubService, (state, action) => {
    const { selectedFakeSubService, ...others } = state
    return {
      ...others,
      selectedFakeSubService: action.fakeService
    }
  }),

  on(AppActions.updateAttachments, (state, action) => {
    const { attachments, ...others } = state
    return {
      attachments: {
        parentAttachments: Array.isArray(action.parentAttachments)
          ? [...action.parentAttachments]
          : [],
        childAttachments: Array.isArray(action.childAttachments)
          ? [...action.childAttachments]
          : []
      },
      ...others
    }
  })
)
