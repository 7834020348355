export const PHONE_NOT_AVAILABLE: string = 'The phone number is not available'
export const OLD_PASSWORD_MATCH: string = 'Old password does not match.'

export const WITH_ALREADY_TAKEN_DATES: string =
  'Your are trying to create a service with already taken dates'
export const PAST_DATES_ERROR = 'Adding past dates is not allowed.'

export const CLIENT_EMAIL_TAKEN = 'This client email is already taken'

export const INVALID_2FA_CODE: string = 'Invalid 2FA code.'
export const CURRENT_DRAFT_ID: string = 'draftId'

export const USER_NOT_FOUND: string = 'User does not exist.'
export const COULD_NOT_SEND_EMAIL: string = 'Could not send email'

export enum LOCALSTORAGE_KEYS {
  CURRENT_SERVICE_STEP = 'serviceStep',
  PARENT_SERVICE_ID = 'parentServiceId',
  LANG = 'lang',
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  CURRENT_USER = 'currentUser',
  CURRENT_MONTH = 'currentMonth',
  CURRENT_YEAR = 'currentYear',
  DRAFT_CLIENT = 'draftClient',
  DRAFT_SERVICE = 'draftService',
  DRAFT_SP = 'draftSp',
  CURRENT_INDEX = 'currentIndex',
  THEME = 'theme',
  EMAIL_TO_BE_VERIFIED = 'emailToBeVerified',
  LAST_DESIGNATIONS = 'lastDesignations',
  CURRENT_VIEW = 'currentView',
  SELECTED_SERVICE_UUID = 'selectedServiceUUID',
  NAV_PROPS = 'NavigationProps'
}

export const MONTHS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12'
]
export const YEARS: number[] = [
  2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
  2031, 2032, 2033, 2034, 2035
]
