import { inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Router } from '@angular/router'
import { Apollo } from 'apollo-angular'
import { AuthenticationService } from '../../modules/authentication/services/authentication.service'
import { SocialAuthService } from '@abacritt/angularx-social-login'
import { AppActions } from './index'
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { CURRENT_DRAFT_ID, LOCALSTORAGE_KEYS } from '../helpers/constants'
import { LocalStorageService } from '../services/local-storage.service'
import { Store } from '@ngrx/store'
import { AppState } from './app.reducers'
import { ServiceDraftService } from 'src/app/modules/services/services/service-draft.service'
import { FakeSubService } from 'src/app/modules/services/@types/faks-sub-service'
import { of } from 'rxjs'

export const logout = createEffect(
  (
    actions$ = inject(Actions),
    authenticationService = inject(AuthenticationService),
    socialAuthService = inject(SocialAuthService),
    apollo = inject(Apollo),
    router = inject(Router),
    localStorageService = inject(LocalStorageService)
    // eslint-disable-next-line max-params
  ) => {
    return actions$.pipe(
      ofType(AppActions.logout),
      tap(() => {
        authenticationService.logout()
        socialAuthService.signOut().then(() => {})
        apollo.client.resetStore().then(() => {})
        apollo.client.stop()
        localStorageService.clear()
        window.location.reload()
        router.navigateByUrl('/auth/sign-in').then(() => {})
      })
    )
  },
  { functional: true, dispatch: false }
)

export const login = createEffect(
  (
    actions$ = inject(Actions),
    localStorageService = inject(LocalStorageService)
  ) =>
    actions$.pipe(
      ofType(AppActions.login),
      tap((action) => {
        localStorageService.setItem(
          LOCALSTORAGE_KEYS.CURRENT_USER,
          action.currentUser
        )
      })
    ),
  { functional: true, dispatch: false }
)

export const tokens = createEffect(
  (
    actions$ = inject(Actions),
    localStorageService = inject(LocalStorageService)
  ) =>
    actions$.pipe(
      ofType(AppActions.tokens),
      tap((action) => {
        localStorageService.setRefreshToken(action.tokens.refreshToken)
        localStorageService.setAccessToken(action.tokens.accessToken)
      })
    ),
  { functional: true, dispatch: false }
)

export const changeMonth = createEffect(
  (
    actions$ = inject(Actions),
    localStorageService = inject(LocalStorageService)
  ) =>
    actions$.pipe(
      ofType(AppActions.changeMonth),
      tap((action) => {
        localStorageService.setItem(
          LOCALSTORAGE_KEYS.CURRENT_MONTH,
          action.month
        )
      })
    ),
  {
    functional: true,
    dispatch: false
  }
)

export const changeYear = createEffect(
  (
    actions$ = inject(Actions),
    localStorageService = inject(LocalStorageService)
  ) =>
    actions$.pipe(
      ofType(AppActions.changeYear),
      tap((action) => {
        localStorageService.setItem(LOCALSTORAGE_KEYS.CURRENT_YEAR, action.year)
      })
    ),
  {
    functional: true,
    dispatch: false
  }
)

export const switchTheme = createEffect(
  (
    actions$ = inject(Actions),
    localStorageService = inject(LocalStorageService)
  ) =>
    actions$.pipe(
      ofType(AppActions.switchTheme),
      tap((action) => {
        localStorageService.setItem(LOCALSTORAGE_KEYS.THEME, `${action.theme}`)
      })
    ),
  {
    functional: true,
    dispatch: false
  }
)

export const leaveEditing = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(ofType(AppActions.leaveEditing)),
  {
    functional: true,
    dispatch: false
  }
)

export const shouldRefreshServices = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(ofType(AppActions.shouldRefreshServices)),
  {
    functional: true,
    dispatch: false
  }
)

export const setCurrentService = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(ofType(AppActions.setCurrentService)),
  {
    functional: true,
    dispatch: false
  }
)

export const showAmount = createEffect(
  (actions$ = inject(Actions)) => actions$.pipe(ofType(AppActions.showAmount)),
  {
    functional: true,
    dispatch: false
  }
)

export const setSelectedMedia = createEffect(
  (actions$ = inject(Actions)) => actions$.pipe(ofType(AppActions.setMedia)),
  {
    functional: true,
    dispatch: false
  }
)

export const removePastDates = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(ofType(AppActions.removePastDates)),
  {
    functional: true,
    dispatch: false
  }
)

export const resetFakeServices = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(ofType(AppActions.resetFakeServices)),
  {
    functional: true,
    dispatch: false
  }
)

export const serviceTimelineData = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(ofType(AppActions.setServiceTimelineData)),
  {
    functional: true,
    dispatch: false
  }
)

export const currentDraft = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store<AppState>),
    localStorageService = inject(LocalStorageService)
  ) =>
    actions$.pipe(
      ofType(AppActions.setServiceDraft),
      map(({ draft }) => {
        localStorageService.removeItem(CURRENT_DRAFT_ID)
        if (draft) {
          localStorageService.setItem(CURRENT_DRAFT_ID, `${draft.id}`)
          store.dispatch(AppActions.setBasicInfo({ info: draft.basicInfo! }))
          store.dispatch(
            AppActions.setBillingInfo({ info: draft.billingInfo! })
          )
          store.dispatch(AppActions.setDatesInfo({ info: draft.datesInfo! }))
        }
      })
    ),
  {
    functional: true,
    dispatch: false
  }
)

export const client = createEffect(
  (
    actions$ = inject(Actions),
    localStorageService = inject(LocalStorageService)
  ) =>
    actions$.pipe(
      ofType(AppActions.setClient),
      tap((action) => {
        localStorageService.setItem(
          LOCALSTORAGE_KEYS.DRAFT_CLIENT,
          action.client
        )
      })
    ),
  {
    dispatch: false,
    functional: true
  }
)

export const serviceProvider = createEffect(
  (
    actions$ = inject(Actions),
    localStorageService = inject(LocalStorageService)
  ) =>
    actions$.pipe(
      ofType(AppActions.setServiceProvider),
      tap((action) => {
        localStorageService.setItem(
          LOCALSTORAGE_KEYS.DRAFT_SP,
          action.serviceProvider
        )
      })
    ),
  {
    dispatch: false,
    functional: true
  }
)

export const setSelectedServiceType = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(ofType(AppActions.setSelectedServiceType)),
  {
    functional: true,
    dispatch: false
  }
)

export const addFakeSubService = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(ofType(AppActions.addFakeSubService)),
  {
    functional: true,
    dispatch: false
  }
)
