import { Injectable, Injector } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, tap } from 'rxjs'
import { environment } from 'src/environments/environment'
import { BroadcastService } from 'src/app/core/broadcast-channel/broadcast.service'
import { CreateUserInfo } from '../@types/create-user.interface'
import { User } from '../@types/user.interface'
import { Store } from '@ngrx/store'
import { CurrentUserGQL } from 'src/generated-types'
import { AppState } from 'src/app/core/store/app.reducers'
import { Theme } from 'src/app/shared/@types/shared-types'
import { AppActions } from 'src/app/core/store'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserGQL?: CurrentUserGQL
  private store?: Store<AppState>
  private broadCastService?: BroadcastService

  constructor(
    private http: HttpClient,
    private injector: Injector
  ) {}

  private getCurrentUserGQL() {
    if (!this.currentUserGQL) {
      this.currentUserGQL = this.injector.get(CurrentUserGQL)
    }
    return this.currentUserGQL
  }

  private getStore() {
    if (!this.store) {
      this.store = this.injector.get(Store)
    }
    return this.store
  }

  private getBroadcastService() {
    if (!this.broadCastService) {
      this.broadCastService = this.injector.get(BroadcastService)
    }
    return this.broadCastService
  }

  authUri = `${environment.BASE_URL}/auth`

  signIn(
    email: string,
    password: string,
    tfaCode?: string
  ): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(`${this.authUri}/sign-in`, {
      email,
      password,
      tfaCode
    })
  }

  signInWithGoogle(token: string): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(`${this.authUri}/google`, { token })
  }

  signUp(newUser: CreateUserInfo): Observable<User> {
    return this.http.post<User>(`${this.authUri}/sign-up`, newUser)
  }

  logout() {
    const broadCastService = this.getBroadcastService()
    broadCastService.publish({
      type: 'logout',
      payload: ''
    })
    return this.http.post(`${this.authUri}/sign-out`, {}).pipe().subscribe()
  }

  confirmEmail(token: string) {
    return this.http.patch(`${this.authUri}/confirm-email?token=${token}`, {})
  }

  resendConfirmEmail(email: string) {
    return this.http.post(`${this.authUri}/resend-confirm-email`, {
      email
    })
  }

  fetchUserInfo() {
    const currentUserGQL = this.getCurrentUserGQL()
    const store = this.getStore()
    return currentUserGQL.watch().valueChanges.pipe(
      tap((res) => {
        const user = {
          id: Number(res.data.me.id),
          uuid: res.data.me.uuid,
          email: res.data.me.email,
          firstName: res.data.me.firstName,
          lastName: res.data.me.lastName,
          middleName: res.data.me.middleName!,
          verified: res.data.me.verified,
          hasPassword: res.data.me.hasPassword,
          isTfaEnabled: res.data.me.isTfaEnabled! || false,
          avatar: res.data.me.avatar!,
          googleId: res.data.me.googleId!,
          phone: {
            phoneNumber: res.data.me.phone?.phoneNumber!,
            countryCode: res.data.me.phone?.country.countryCode!,
            dialCode: res.data.me.phone?.country.dialCode!
          },
          profile: {
            id: res.data.me.profile.id,
            currency: res.data.me.profile.currency! || 'EUR',
            theme: res.data.me.profile.theme! as Theme,
            enablePastDates: res.data.me.profile.enablePastDates!
          }
        }
        store.dispatch(
          AppActions.login({
            currentUser: user
          })
        )
      })
    )
  }

  changePassword(
    oldPassword: string | null,
    newPassword: string | null,
    confirmPassword: string | null
  ) {
    return this.http.patch(`${this.authUri}/update-password`, {
      oldPassword,
      newPassword,
      confirmPassword
    })
  }

  sendPasswordResetEmail(email: string) {
    return this.http.post(`${this.authUri}/send-password-reset-email`, {
      email
    })
  }

  resetPassword(
    resetToken: string,
    newPassword: string,
    confirmPassword: string
  ) {
    const passwords = { newPassword, confirmPassword }
    return this.http.patch(
      `${this.authUri}/reset-password?resetToken=${resetToken}`,
      passwords
    )
  }

  checkResetPasswordTokenValidity(resetToken: string) {
    return this.http.patch(
      `${this.authUri}/check-reset-password-token?resetToken=${resetToken}`,
      {}
    )
  }

  refreshTokens(refreshToken: string): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(`${this.authUri}/refresh-tokens`, {
      refreshToken
    })
  }

  updateProfile(updatedUser: UpdateUserProfileInfo) {
    return this.http.patch(
      `${environment.BASE_URL}/profile/update`,
      updatedUser
    )
  }

  updateEmail(newEmail: string, newEmailConfirmation: string) {
    return this.http.patch(`${this.authUri}/update-email`, {
      newEmail,
      newEmailConfirmation
    })
  }

  updateTheme(newTheme: string) {
    return this.http.patch(`${environment.BASE_URL}/profile/update-theme`, {
      theme: newTheme
    })
  }

  enableOrDisableMultiService() {
    return this.http.patch(
      `${environment.BASE_URL}/profile/multi-services-on-same-dates`,
      {}
    )
  }

  checkIfPhoneNumberTaken(countryCode: string, phoneNumber: string) {
    return this.http.post(`${environment.BASE_URL}/phones/check-if-taken`, {
      countryCode,
      phoneNumber
    })
  }

  generate2FA() {
    return this.http.patch(
      `${this.authUri}/2fa/generate`,
      {},
      { responseType: 'blob' }
    )
  }

  disable2FA() {
    return this.http.patch(`${this.authUri}/2fa/disable`, {})
  }

  deleteUserAccount() {
    return this.http.post(`${environment.BASE_URL}/request`, {
      requestType: 'delete_account'
    })
  }

  confirmAccountDeletion(id: number) {
    return this.http.patch(`${environment.BASE_URL}/request/${id}`, {})
  }
}

export type TokenResponse = {
  accessToken: string
  refreshToken: string
}

export interface UpdateUserProfileInfo {
  firstName: string
  lastName: string
  middleName?: string
  currency: string
  theme: Theme
  notifyBySms: boolean
}
