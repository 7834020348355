<form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
  <div class="fields">
    <app-input
      [control]="signupForm.controls.firstName"
      placeholder="inputs.firstName"
      type="text"
      [required]="true"
    ></app-input>
    <app-input
      [control]="signupForm.controls.lastName"
      placeholder="inputs.lastName"
      type="text"
      [required]="true"
    ></app-input>
    <app-input
      [control]="signupForm.controls.middleName"
      placeholder="inputs.middleName"
      type="text"
    ></app-input>
    <app-input
      [control]="signupForm.controls.email"
      placeholder="inputs.email"
      type="email"
      [required]="true"
      [readonly]="isSignup ? false : true"
    ></app-input>
    <!-- <div class="tip">
      <span>*</span> {{ 'inputs.phoneNumberTip' | translate }}
    </div> -->
    <!-- <ngx-intl-tel-input
      [cssClass]="'custom'"
      [enableAutoCountrySelect]="false"
      [separateDialCode]="true"
      [numberFormat]="PhoneNumberFormat.National"
      [selectedCountryISO]="CountryISO.UnitedKingdom"
      [searchCountryFlag]="true"
      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
      [selectFirstCountry]="false"
      [maxLength]="15"
      [phoneValidation]="true"
      name="phone"
      formControlName="phone"
    ></ngx-intl-tel-input> -->
    <!-- <div *ngIf="displayErrors()" class="errors">
      <div *ngIf="signupForm.controls.phone.errors?.['required']!">
        {{ 'inputs.validations.required' | translate }}
      </div>
      <div *ngIf="signupForm.controls['phone'].invalid!">
        {{ 'inputs.validations.phoneNumber' | translate }}
      </div>
    </div> -->
    <div class="tip"><span>*</span> {{ 'inputs.passwordTip' | translate }}</div>
    <app-input
      class="password"
      [control]="signupForm.controls.password"
      placeholder="inputs.password"
      [type]="!togglePasswordField ? 'password' : 'text'"
      [required]="true"
    ></app-input>
    <app-input
      class="password"
      [control]="signupForm.controls.confirmPassword"
      placeholder="inputs.confirmPassword"
      [type]="!togglePasswordField ? 'password' : 'text'"
      [required]="true"
    ></app-input>
  </div>
  <div class="show-password" (click)="showPassword()">
    <div class="box">
      <i *ngIf="togglePasswordField" class="fa-solid fa-check"></i>
    </div>
    <div class="label">{{ 'inputs.showPassword' | translate }}</div>
  </div>
  <p class="error" *ngIf="errorMessage !== ''">
    {{ errorMessage }}
  </p>
  <div class="buttons">
    <button
      type="submit"
      class="submit-btn"
      [ngClass]="{ disabled: signupForm.invalid }"
      [attr.disabled]="signupForm.invalid ? true : null"
      matTooltip="{{
        signupForm.invalid ? ('inputs.invalidFormTooltip' | translate) : null
      }}"
      *ngIf="true"
    >
      {{ 'inputs.saveBtnLabel' | translate }}
    </button>

    <div *ngIf="isSignup" (click)="resetForm()">
      {{ 'inputs.cancelBtnLabel' | translate }}
    </div>
  </div>
</form>
