import { Component, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { LOCALSTORAGE_KEYS } from 'src/app/core/helpers/constants'
import { LocalStorageService } from 'src/app/core/services/local-storage.service'

@Component({
  selector: 'app-switch-lang',
  templateUrl: './switch-lang.component.html',
  styleUrls: ['./switch-lang.component.scss']
})
export class SwitchLangComponent {
  @Input() isDarkTheme = false
  @Input() removeBg = false

  constructor(
    public translate: TranslateService,
    private localStorageService: LocalStorageService
  ) {}

  switchLang(lang: string) {
    this.translate.use(lang)
    this.localStorageService.setItem(LOCALSTORAGE_KEYS.LANG, lang)
  }
}
