export const environment = {
  production: false,
  GOOGLE_CLIENT_ID:
    '293950147859-rllmu595suk3qqln74j2c8smsk6nqjnh.apps.googleusercontent.com',
  BASE_URL: 'https://api.zcrm-dev.152.53.54.190.sslip.io',
  //BASE_URL: 'http://127.0.0.1:3000',
  WSS_URI: 'https://api.zcrm-dev.152.53.54.190.sslip.io/graphql',
  firebaseConfig: {
    apiKey: 'AIzaSyDCC5J743Dkg8c7ZNjr3ulFWlfTfOVHflw',
    authDomain: 'zcrm-416307.firebaseapp.com',
    projectId: 'zcrm-416307',
    storageBucket: 'zcrm-416307.appspot.com',
    messagingSenderId: '185697831792',
    appId: '1:185697831792:web:903882f89130dec1969db5',
    measurementId: 'G-VY3NMEQC95'
  },
  spaceId: 'hoeuoffwjihu',
  accessToken: 'PBM5BE5BxsuJmPRd2Nkvddbl0-oqTzC_Qzr2B6wcVcY'
}
