import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs'

@Injectable()
export class UserCurrentLocationService {
  constructor(private http: HttpClient) {}

  getCountry() {
    return this.http
      .get('https://ipinfo.io/json?token=1950c1a016b22d')
      .pipe(map((res: any) => res))
  }
}

export type UserCountry = {
  country: string
  city: string
}
