import { createAction, props } from '@ngrx/store'
import { User as CurrentUser } from '../../modules/authentication/@types/user.interface'
import { TokenResponse } from '../../modules/authentication/services/authentication.service'
import {
  BasicServiceInfo,
  BillingServiceInfo,
  DatesServiceInfo,
  ServiceTimelineData
} from './app.reducers'
import { Client, Service, User } from 'src/generated-types'
import {
  AttachmentDraft,
  FakeSubServiceDraft,
  ServiceDraft
} from 'src/app/modules/services/@types/service-draft'
import { ServiceType } from 'src/app/modules/services/@types/service-type'
import { FakeSubService } from 'src/app/modules/services/@types/faks-sub-service'

// Auth Actions
export const login = createAction(
  '[Login Page] User Logged IN',
  props<{ currentUser: CurrentUser }>()
)

export const tokens = createAction(
  '[After Successful Login Or Successful Refresh Token]  ',
  props<{ tokens: TokenResponse }>()
)

export const logout = createAction('[Topbar] User Logout')

//Calendar

export const changeMonth = createAction(
  '[Calendar] User Change Month',
  props<{ month: number }>()
)

export const changeYear = createAction(
  '[Calendar] User Change Year',
  props<{ year: number }>()
)

//Shared
export const switchTheme = createAction(
  '[Top bar] User Change Theme',
  props<{ theme: string }>()
)

export const leaveEditing = createAction(
  '[Settings Page] User Update Profile',
  props<{ leaveEditing: boolean }>()
)

export const shouldRefreshServices = createAction(
  '[When User delete a service] on delete service modale',
  props<{ shouldRefreshServices: boolean }>()
)

export const setCurrentService = createAction(
  '[Create -Edit Service Form] after creation or edition',
  props<{ service: Service | null }>()
)

export const showAmount = createAction(
  '[Calendar-details-list-chart] show or hide amount',
  props<{ showAmount: boolean }>()
)

export const setMedia = createAction(
  '[Media Click] use want to see in full the media',
  props<{ media: { url: string; mediaType: string } | null }>()
)

export const removePastDates = createAction(
  '[Service Form - Step 3] user select dates info with past dates',
  props<{ removePastDates: boolean }>()
)

export const setServiceTimelineData = createAction(
  '[Calendar - Details page] user select a service to see the working dates',
  props<{ data: ServiceTimelineData | null }>()
)

export const setServiceDraft = createAction(
  '[Pick a draft] on new service modal or draft page',
  props<{ draft: ServiceDraft | null }>()
)

export const setClient = createAction(
  '[Service provider & Client] User Edit or Add Client || Service Provider',
  props<{ client: Client | null }>()
)

export const setServiceProvider = createAction(
  '[Add or Edit Service Provider] to be invited to join ',
  props<{ serviceProvider: User | null }>()
)

export const setSelectedServiceType = createAction(
  '[On Page load for update service] user load the page',
  props<{ serviceType: ServiceType | null }>()
)

export const setBasicInfo = createAction(
  '[Create - Edit Page Step I] after select draft or load service',
  props<{ info: BasicServiceInfo | null }>()
)

export const setBillingInfo = createAction(
  '[Create - Edit Page Step II] after select draft or load service',
  props<{ info: BillingServiceInfo | null }>()
)

export const setDatesInfo = createAction(
  '[Create - Edit Page Step III] after select draft or load service',
  props<{ info: DatesServiceInfo | null }>()
)

export const addFakeSubService = createAction(
  '[Create Parent Service] after parent creation or edition',
  props<{ fakeService: FakeSubService }>()
)

export const resetFakeServices = createAction(
  '[Update service] user load the page',
  props<{ fakeServices: [] }>()
)

export const updateAttachments = createAction(
  '[Attachment  and Child Attachment] user upload or update the attachments',
  props<{
    parentAttachments: AttachmentDraft[]
    childAttachments: AttachmentDraft[]
  }>()
)

export const setSelectedFakeSubService = createAction(
  '[Service Provider Component] user select provider to sign dates ',
  props<{ fakeService: FakeSubServiceDraft | null }>()
)
