<div class="container">
  <div class="left">
    <app-banner></app-banner>
  </div>
  <div class="right">
    <div class="top">
      <img
        src="https://res.cloudinary.com/dghlwp1oo/image/upload/v1723485486/logo_dhnayu.png"
        loading="lazy"
        alt="Logo"
        class="top-logo"
      />
      <app-switch-lang></app-switch-lang>
    </div>
    <div class="form">
      <div
        class="title"
        *ngIf="!widget.hasAccountSet && !widget.error && result"
      >
        {{ 'acceptInvitation.title' | translate }}
      </div>
      <p class="intro" *ngIf="!widget.hasAccountSet && !widget.error && result">
        {{ 'acceptInvitation.uncompletedAccount' | translate }}
      </p>
      <app-sign-up-form
        *ngIf="!widget.hasAccountSet && !widget.error && result"
        [id]="result.id!"
        [firstName]="result.firstName!"
        [lastName]="result.lastName!"
        [middleName]="result.middleName!"
        [email]="result.email!"
        [isSignup]="false"
      ></app-sign-up-form>
      <div
        class="congratulations"
        *ngIf="widget.hasAccountSet && !widget.error"
      >
        <img
          src="https://res.cloudinary.com/dghlwp1oo/image/upload/v1723485489/congratulation_bkk0zh.png"
          loading="lazy"
          alt="Accep Invitation"
        />
        <p>
          {{ 'acceptInvitation.success.left' | translate }}
          <span>{{ result.serviceDesignation }}</span>
          {{ 'acceptInvitation.success.right' | translate }}
        </p>
      </div>
      <div class="error-widget" *ngIf="widget.error">
        <img
          src="https://res.cloudinary.com/dghlwp1oo/image/upload/v1723485486/not-found_gfrelm.webp"
          loading="lazy"
          alt="Not Found"
        />
        <div class="message">
          <p>{{ 'acceptInvitation.errorMessage' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="footer" *ngIf="widget.error || widget.hasAccountSet">
      {{ 'acceptInvitation.footer.left' | translate }}
      <span (click)="handleNavigation('/auth/sign-in')"
        >{{ 'acceptInvitation.footer.right' | translate }}
      </span>
    </div>
  </div>
</div>
<app-spinner *ngIf="isLoading" [isFullScreen]="false"></app-spinner>
