type IAMErrors =
  | 'ERR_PASS_FORMAT'
  | 'ERR_COUNTRY_CODE_REQUIRE_PHONE'
  | 'ERR_PHONE_REQUIRE_COUNTY_CODE'
  | 'ERR_UNUSED_PHONE_NUMBER'
  | 'ERR_USER_NOT_FOUND'
  | 'ERR_NO_PASS_AUTH_NEEDED'
  | 'ERR_PASS_NOT_MATCH'
  | 'ERR_INVALID_2FA_CODE'
  | 'ERR_REFRESH_TOKEN_INVALID'
  | 'ERR_ACCESS_DENIED'
  | 'ERR_PASS_CONFIRM_NOT_MATCH'
  | 'ERR_OLD_PASS_NOT_MATCH'
  | 'ERR_REFRESH_TOKEN_NOT_PROVIDED'
  | 'ERR_ACCESS_TOKEN_EXPIRED'
  | 'ERR_EMAIL_CONFIRM_NOT_MATCH'
  | 'ERR_EMAIL_IN_USE'
  | 'ERR_PHONE_NUMBER_ALREADY_IN_USE'
  | 'ERR_INVALID_COUNTRY_CODE'
  | 'ERR_ACCOUNT_NOT_VERIFIED'
  | 'ERR_INVALID_CREDENTIALS'
  | 'ACCOUNT_ALREADY_VERIFIED'
  | 'ERR_INVALID_THEME'
  | 'ERR_PROFILE_NOT_FOUND'
  | 'COULD_NOT_SEND_EMAIL'
  | 'ERR_INVITATION_NOT_FOUND'
  | 'ERR_UPDATE_BAD_REQUEST'

type IAMErr = {
  [key in IAMErrors]: any
}

export const IAM_ERRORS: IAMErr = {
  ERR_PASS_FORMAT:
    'Password should have at least 8 characters containing at least 1 uppercase, 1 lowercase,1 digital and 1 special character.',
  ERR_COUNTRY_CODE_REQUIRE_PHONE:
    'countryCode is required if phoneNumber is provided.',
  ERR_PHONE_REQUIRE_COUNTY_CODE:
    'phoneNumber is required if countryCode is provided.',
  ERR_UNUSED_PHONE_NUMBER: (dialCode: string, phoneNumber: string) =>
    `Le phone number ${dialCode} ${phoneNumber} is not used.`,
  ERR_USER_NOT_FOUND: 'User does not exist.',
  ERR_NO_PASS_AUTH_NEEDED:
    'This type of account does not require password authentication.',
  ERR_PASS_NOT_MATCH: 'User does not exist.',
  ERR_INVALID_2FA_CODE: 'Invalid 2FA code.',
  ERR_REFRESH_TOKEN_INVALID: 'Refresh token is invalid.',
  ERR_ACCESS_DENIED: 'Access denied.',
  ERR_PASS_CONFIRM_NOT_MATCH:
    "New password and confirmation password don't match.",
  ERR_OLD_PASS_NOT_MATCH: 'Old password does not match.',
  ERR_REFRESH_TOKEN_NOT_PROVIDED: 'Reset token not provided.',
  ERR_ACCESS_TOKEN_EXPIRED: 'Expired token.',
  ERR_EMAIL_CONFIRM_NOT_MATCH:
    'New email and confirmation email are different.',
  ERR_EMAIL_IN_USE: 'The email is already taken',
  ERR_PHONE_NUMBER_ALREADY_IN_USE: 'The phone number is not available',
  ERR_INVALID_COUNTRY_CODE: 'The country code is not valid',
  ERR_ACCOUNT_NOT_VERIFIED: 'This account is not verified',
  ERR_INVALID_CREDENTIALS: 'Wrong credential.',
  ACCOUNT_ALREADY_VERIFIED: 'Your account is already verified.',
  ERR_INVALID_THEME: 'Invalid Theme',
  ERR_PROFILE_NOT_FOUND: 'Profile not found',
  COULD_NOT_SEND_EMAIL: 'Could not send email',
  ERR_INVITATION_NOT_FOUND: 'Invitation not found',
  ERR_UPDATE_BAD_REQUEST: 'Bad Request Exception'
}

type ServicesErrors =
  | 'ERR_SERVICE_PROVIDER_RATE_LESS_OR_EQUAL_THAT_SERVICE_RATE'
  | 'ERR_INVALID_CURRENCY'
  | 'ERR_PASSED_DATES'
  | 'ERR_SERVICE_NOT_FOUND'
  | 'ERR_SERVICE_WITH_TAKEN_DATES'
  | 'SERVICE_DAILY_RATE'
  | 'SERVICE_PROVIDER_DAILY_RATE'
  | 'SERVICE_DATES'
  | 'SERVICE_TITLE'
  | 'SERVICE_STATUS'
  | 'ERR_EXCEEDED_NUMBER_OF_HOURS'
  | 'ERR_EXCEEDED_PARENT_RATE'
  | 'PAST_DATES_DISABLED'
  | 'REQUIRED_SERVICE_RATE'
  | 'BAD_BILLING'
  | 'NUMBER_OF_HOURS_REQUIRED'
  | 'CHILD_DATE_NOT_IN_PARENT_DATE'
  | 'CHILD_DATE_TAKEN'
  | 'NOT_GLOBAL_BILLING'
  | 'PARENT_DATE_NOT_DEFINED'
  | 'NOT_GLOBAL_BILLING_PARENT'
  | 'ONLY_ACTIVE_INACTIVE'
  | 'ERR_CLIENT_NOT_FOUND'
  | 'CLIENT_EMAIL_TAKEN'
  | 'INVALID_PHONE_NUMBER'
  | 'ERR_PHONE_NUMBER_ALREADY_IN_USE'
  | 'ERR_DELETE_SERVICE'
  | 'COULD_NOT_SEND_EMAIL'

type ServicesError = {
  [keys in ServicesErrors]: string
}

export const SERVICES_ERRORS: ServicesError = {
  ERR_SERVICE_PROVIDER_RATE_LESS_OR_EQUAL_THAT_SERVICE_RATE:
    'Service provider  rate <= Service service rate.',
  ERR_INVALID_CURRENCY: 'The currency is not valid.',
  ERR_PASSED_DATES: 'Services cannot be created in the past.',
  ERR_SERVICE_NOT_FOUND: `Service not found`,
  ERR_SERVICE_WITH_TAKEN_DATES:
    'Your are trying to create a service with already taken dates',
  ERR_EXCEEDED_NUMBER_OF_HOURS: 'Exceeded day numberOfHours',
  ERR_EXCEEDED_PARENT_RATE: 'Exceeded parent rate',
  PAST_DATES_DISABLED: 'Adding past dates is not allowed.',
  REQUIRED_SERVICE_RATE:
    'The service rate is required for billing for the service',
  BAD_BILLING: 'Invalid billing type',
  NUMBER_OF_HOURS_REQUIRED:
    'number of hours is required for this type of billing',
  CHILD_DATE_NOT_IN_PARENT_DATE:
    'All child service dates must be included in the parent dates.',
  CHILD_DATE_TAKEN:
    'Some of these dates are already taken by other child services.',

  NOT_GLOBAL_BILLING: 'In this case, the rate  must be specified.',
  PARENT_DATE_NOT_DEFINED:
    'In this case, the parent dates must be an empty array.',
  NOT_GLOBAL_BILLING_PARENT:
    "In this case, you don't need to specify the rate in the parent service.",
  SERVICE_DAILY_RATE: "It's the service daily rate.",
  SERVICE_PROVIDER_DAILY_RATE:
    "It's the service provider daily rate.It should be <= Daily rate.",
  SERVICE_DATES: 'Dates for the service.',
  SERVICE_TITLE: 'Service title.',
  SERVICE_STATUS:
    "The status can only takes the following values : 'option|confirmed|billed|cancelled|paid'",
  ONLY_ACTIVE_INACTIVE: "Can only takes the values 'active' OR 'inactive'",
  ERR_CLIENT_NOT_FOUND: 'Client not found.',
  CLIENT_EMAIL_TAKEN: 'The email is already taken',
  INVALID_PHONE_NUMBER: 'The phone number is not available',
  ERR_PHONE_NUMBER_ALREADY_IN_USE: 'The phone number is not available',
  COULD_NOT_SEND_EMAIL: 'Could not send email',
  ERR_DELETE_SERVICE:
    'Deletion of confirmed, archived, or billed services is not allowed.'
}
