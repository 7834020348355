<div
  class="lang"
  [class.darktheme]="isDarkTheme"
  [class.withoutBg]="removeBg"
  [class.withoutBgDark]="removeBg && isDarkTheme"
>
  <select
    #selectedLang
    (change)="switchLang(selectedLang.value)"
    class="lang-box"
    [class.nobBg]="removeBg"
  >
    <option
      *ngFor="let language of translate.getLangs()"
      [value]="language"
      [selected]="language === translate.currentLang"
      class="lang-box-lg"
    >
      {{ language | uppercase }}
    </option>
  </select>
  <div class="arrow">
    <i class="fa-solid fa-chevron-down"></i>
  </div>
</div>
