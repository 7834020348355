<div class="main" [class.dark]="isDarkTheme">
  <router-outlet></router-outlet>
  <app-media-modal></app-media-modal>
</div>

<!-- <div class="maintenance-container">
  <img
    class="logo"
    src="https://www.zabibu.co/assets/images/logo/Logo%20Dark.png"
    alt="Logo"
  />
  <h1 class="maintenance-header">We'll be back soon!</h1>
  <p class="maintenance-message">
    Our website is currently undergoing scheduled maintenance. We should be back
    shortly. Thank you for your patience.
  </p>
  <div class="animated-text">Maintenance...</div>
</div> -->
