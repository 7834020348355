import { ToastrService } from 'ngx-toastr'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class ToastHelper {
  constructor(private toastService: ToastrService) {}

  successToast(title: string, description: string) {
    return this.toastService.success(description, title)
  }

  errorToast(title: string, description: string) {
    return this.toastService.error(description, title)
  }

  warningToast(title: string, description: string) {
    return this.toastService.warning(description, title)
  }

  infoToast(title: string, description: string) {
    return this.toastService.info(description, title)
  }
}
