export enum Theme {
  Dark = 'dark',
  Light = 'light'
}
export type serviceStatus =
  | 'option'
  | 'confirmed'
  | 'billed'
  | 'paid'
  | 'archived'
  | 'canceled'
