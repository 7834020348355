import {
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import {
  catchError,
  filter,
  interval,
  Subscription,
  tap,
  throwError
} from 'rxjs'
import { AuthenticationService } from './modules/authentication/services/authentication.service'
import { BroadcastService } from './core/broadcast-channel/broadcast.service'
import { AppState, ServiceView } from './core/store/app.reducers'
import { AppActions, fromAppSelector } from './core/store'
import { HttpErrorResponse } from '@angular/common/http'
import { CURRENT_DRAFT_ID, LOCALSTORAGE_KEYS } from './core/helpers/constants'
import { LocalStorageService } from './core/services/local-storage.service'
import { isPlatformBrowser } from '@angular/common'
import { Theme } from './shared/@types/shared-types'
import { NavigationProps } from './modules/services/@types/navigation-props'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isLoggedOut: boolean = false
  subscription = new Subscription()
  private translate: TranslateService
  private store: Store<AppState>
  private router: Router
  private authenticationService: AuthenticationService
  private localStorageService: LocalStorageService
  private broadCastService: BroadcastService
  isDarkTheme = false

  constructor(
    private injector: Injector,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.translate = this.injector.get(TranslateService)
    this.store = this.injector.get(Store)
    this.router = this.injector.get(Router)
    this.authenticationService = this.injector.get(AuthenticationService)
    this.localStorageService = this.injector.get(LocalStorageService)
    this.broadCastService = this.injector.get(BroadcastService)

    this.setupLanguage()
    this.setupTokenRefresh()
    this.setupRouterEvents()
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.initializeAppState()
      this.listenForLogout()
    }

    this.store.pipe(select(fromAppSelector.currentTheme)).subscribe((theme) => {
      this.isDarkTheme = theme === Theme.Dark
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  private setupLanguage() {
    const lang = isPlatformBrowser(this.platformId)
      ? JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.LANG)!) ||
        this.translate.getBrowserLang()
      : this.translate.getBrowserLang()

    const supportedLangs = ['en', 'fr']
    this.translate.addLangs(supportedLangs)
    this.translate.setDefaultLang(supportedLangs.includes(lang) ? lang : 'fr')
  }

  private setupTokenRefresh() {
    if (isPlatformBrowser(this.platformId) && !this.isLoggedOut) {
      this.subscription.add(
        interval(10 * 60 * 1000).subscribe(() => {
          const refreshToken = this.localStorageService.getItem(
            LOCALSTORAGE_KEYS.REFRESH_TOKEN
          )
          const accessToken = this.localStorageService.getItem(
            LOCALSTORAGE_KEYS.ACCESS_TOKEN
          )

          if (refreshToken && accessToken) {
            this.authenticationService
              .refreshTokens(refreshToken as string)
              .pipe(
                tap((res) =>
                  this.store.dispatch(AppActions.tokens({ tokens: res }))
                ),
                catchError((err: HttpErrorResponse) =>
                  throwError(() => new Error(err.message))
                )
              )
              .subscribe()
          }
        })
      )
    }
  }

  private setupRouterEvents() {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.add(
        this.router.events
          .pipe(
            filter(
              (event): event is NavigationEnd => event instanceof NavigationEnd
            )
          )
          .subscribe((event) => {
            if (
              event.id === 1 &&
              event.url === event.urlAfterRedirects &&
              !this.isLoggedOut
            ) {
              this.authenticationService.fetchUserInfo().subscribe()

              this.localStorageService.removeItem(CURRENT_DRAFT_ID)

              const refreshToken = this.localStorageService.getItem(
                LOCALSTORAGE_KEYS.REFRESH_TOKEN
              )
              if (refreshToken) {
                this.authenticationService
                  .refreshTokens(refreshToken as string)
                  .pipe(
                    tap((res) =>
                      this.store.dispatch(AppActions.tokens({ tokens: res }))
                    )
                  )
                  .subscribe()
              }
            }
          })
      )
    }
  }

  private initializeAppState() {
    const currentUser = this.localStorageService.getItem(
      LOCALSTORAGE_KEYS.CURRENT_USER
    )

    const theme = this.localStorageService.getItem(LOCALSTORAGE_KEYS.THEME)
    const currentYear =
      Number(
        this.localStorageService.getItem(LOCALSTORAGE_KEYS.CURRENT_YEAR)
      ) || new Date().getFullYear()
    const currentMonth =
      Number(
        this.localStorageService.getItem(LOCALSTORAGE_KEYS.CURRENT_MONTH)
      ) || new Date().getMonth()

    if (currentUser) {
      this.store.dispatch(
        AppActions.login({ currentUser: Object(currentUser) })
      )
    }

    this.store.dispatch(AppActions.changeMonth({ month: currentMonth }))
    this.store.dispatch(AppActions.changeYear({ year: currentYear }))
    this.store.dispatch(AppActions.switchTheme({ theme: theme as string }))
  }

  private listenForLogout() {
    this.subscription.add(
      this.store
        .pipe(select(fromAppSelector.isLoggedOut))
        .subscribe((isLoggedOut) => {
          this.isLoggedOut = isLoggedOut
        })
    )

    this.subscription.add(
      this.broadCastService.messagesOfType('logout').subscribe(() => {
        window.location.reload()
      })
    )
  }
}
