import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import {
  Observable,
  catchError,
  concatMap,
  of,
  switchMap,
  throwError
} from 'rxjs'
import { environment } from '../../../environments/environment'
import { AuthenticationService } from '../../modules/authentication/services/authentication.service'
import { AppActions } from '../store'
import { AppState } from '../store/app.reducers'
import { LocalStorageService } from '../services/local-storage.service'
import { LOCALSTORAGE_KEYS } from '../helpers/constants'

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  private refreshInProgress = false

  constructor(
    private authService: AuthenticationService,
    private store: Store<AppState>,
    private localStorageService: LocalStorageService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const authToken = this.localStorageService.getItem(
      LOCALSTORAGE_KEYS.ACCESS_TOKEN
    )

    const authorizedRequest = this.addAuthorizationHeader(
      request,
      authToken as string
    )

    if (this.isRefreshTokenRequest(authorizedRequest)) {
      return next.handle(this.clearAuthorizationHeader(authorizedRequest))
    }

    if (this.isGraphqlRequest(authorizedRequest)) {
      return this.handleGraphqlRequest(authorizedRequest, next)
    }

    return this.handleRequestWithErrorHandling(authorizedRequest, next)
  }

  // eslint-disable-next-line class-methods-use-this
  private addAuthorizationHeader(
    request: HttpRequest<unknown>,
    token: string | null
  ): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  // eslint-disable-next-line class-methods-use-this
  private clearAuthorizationHeader(
    request: HttpRequest<unknown>
  ): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: ''
      }
    })
  }

  // eslint-disable-next-line class-methods-use-this
  private isRefreshTokenRequest(request: HttpRequest<unknown>): boolean {
    const refreshTokenUrl = `${environment.BASE_URL}/auth/refresh-tokens`
    return request.url === refreshTokenUrl
  }

  // eslint-disable-next-line class-methods-use-this
  private isGraphqlRequest(request: HttpRequest<unknown>): boolean {
    return request.url.includes('/graphql')
  }

  private handleGraphqlRequest(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      concatMap((event) => {
        if (this.needsAuthentication(event)) {
          return this.handleTokenRefresh(request, next)
        }
        return of(event)
      })
    )
  }

  // eslint-disable-next-line class-methods-use-this
  private needsAuthentication(event: HttpEvent<any>): boolean {
    if (
      event.type === HttpEventType.Response &&
      event.status === 200 &&
      event.body &&
      Array.isArray(event.body.errors)
    ) {
      return event.body.errors.some(
        (e: { extensions: { code: string } }) =>
          e.extensions?.code === 'UNAUTHENTICATED'
      )
    }
    return false
  }

  private handleRequestWithErrorHandling(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe(catchError((err) => this.handleError(err, request, next)))
  }

  private handleError(
    err: HttpErrorResponse,
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      err.status === 401 &&
      !this.refreshInProgress &&
      this.shouldRefreshToken(request)
    ) {
      this.refreshInProgress = true
      return this.handleTokenRefresh(request, next)
    }
    this.refreshInProgress = false
    return throwError(() => err)
  }

  // eslint-disable-next-line class-methods-use-this
  private shouldRefreshToken(request: HttpRequest<unknown>): boolean {
    return (
      !request.url.includes(`${environment.BASE_URL}/auth`) &&
      !request.url.includes('delete-account')
    )
  }

  private handleTokenRefresh(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.authService.refreshTokens(this.getRefreshToken()!).pipe(
      switchMap((res) => {
        this.store.dispatch(AppActions.tokens({ tokens: res }))
        const authorizedRequest = this.addAuthorizationHeader(
          request,
          res.accessToken
        )
        return next.handle(authorizedRequest)
      }),
      catchError(() => {
        this.store.dispatch(AppActions.logout())
        return throwError(() => new HttpErrorResponse({ status: 401 }))
      })
    )
  }

  private getRefreshToken(): string | null {
    return this.localStorageService.getItem(LOCALSTORAGE_KEYS.REFRESH_TOKEN)
  }
}
