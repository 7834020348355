<div
  class="input-field"
  [class.invalid]="control.invalid && displayErrors()"
  [class.darktheme]="isDarkTheme && darker"
>
  <input
    [type]="type"
    [formControl]="control"
    [required]="required"
    [readonly]="readonly"
    placeholder=" "
    [class.disabled]="readonly"
  />

  <label class="label">
    <span class="required" *ngIf="required">*</span
    ><span>{{ placeholder | translate }}</span
    ><span class="optional" *ngIf="!required"
      >({{ 'inputs.optional' | translate }})</span
    >
  </label>
</div>
<div *ngIf="displayErrors()" class="errors">
  <div *ngIf="control.errors?.['required']!">
    {{ 'inputs.validations.required' | translate }}
  </div>
  <div *ngIf="control.errors?.['email']!">
    {{ 'inputs.validations.email' | translate }}
  </div>
  <div *ngIf="control.errors?.['pattern']!">
    {{
      type === 'email'
        ? ('inputs.validations.email' | translate)
        : ('inputs.validations.password' | translate)
    }}
  </div>
  <div *ngIf="control.errors?.['minlength']!">
    {{ 'inputs.validations.minLength.left' | translate }}
    {{ (control.errors?.['minlength'])!.actualLength }}
    {{ 'inputs.validations.minLength.right' | translate }}
    {{ (control.errors?.['minlength'])!.requiredLength }}
  </div>
  <div *ngIf="control.errors?.['min']!">
    {{ 'inputs.validations.min' | translate }}
  </div>
</div>
