import { isPlatformBrowser } from '@angular/common'
import { Injectable, Inject, PLATFORM_ID } from '@angular/core'
import { LOCALSTORAGE_KEYS } from '../helpers/constants'

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  getItem<T>(key: string): T | null {
    const item = isPlatformBrowser(this.platformId)
      ? localStorage.getItem(key)
      : null

    return JSON.parse(item!)
  }

  setItem<T>(key: string, value: T): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(key, JSON.stringify(value))
    }
  }

  setAccessToken(accessToken: string): void {
    this.setItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN, accessToken)
  }

  setRefreshToken(refreshToken: string): void {
    this.setItem(LOCALSTORAGE_KEYS.REFRESH_TOKEN, refreshToken)
  }

  removeItem(key: string): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(key)
    }
  }

  clear(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear()
    }
  }
}
