import { createFeatureSelector, createSelector } from '@ngrx/store'
import { AppState } from './app.reducers'
import { fromAppState } from './index'

export const selectAppState = createFeatureSelector<AppState>(
  fromAppState.appSelector
)
export const isLoggedIn = createSelector(
  selectAppState,
  (state) => state.currentUser !== null
)
export const isLoggedOut = createSelector(isLoggedIn, (loggedIn) => !loggedIn)

export const currentUser = createSelector(
  selectAppState,
  (state) => state.currentUser
)

export const tokens = createSelector(selectAppState, (state) => state.tokens)

export const currentMonth = createSelector(
  selectAppState,
  (control) => control.month
)
export const currentYear = createSelector(
  selectAppState,
  (control) => control.year
)

export const currentTheme = createSelector(
  selectAppState,
  (control) => control.theme
)

export const leaveEditing = createSelector(
  selectAppState,
  (control) => control.leaveEditing
)

export const shouldRefreshServices = createSelector(
  selectAppState,
  (selector) => selector.shouldRefreshServices
)

export const createService = createSelector(
  selectAppState,
  (selector) => selector.createdServiceInfo
)

export const showAmount = createSelector(
  selectAppState,
  (selector) => selector.showAmount
)

export const selectedMedia = createSelector(
  selectAppState,
  (selector) => selector.selectedMedia
)

export const removePastDates = createSelector(
  selectAppState,
  (selector) => selector.removePastDates
)

export const serviceTimelineData = createSelector(
  selectAppState,
  (selector) => selector.serviceTimelineData
)

export const currentDraft = createSelector(
  selectAppState,
  (selector) => selector.serviceDraft
)

export const serviceType = createSelector(
  selectAppState,
  (selector) => selector.serviceType
)

export const getClient = createSelector(
  selectAppState,
  (control) => control.client
)

export const getServiceProvider = createSelector(
  selectAppState,
  (control) => control.serviceProvider
)
export const selectedFakeProvider = createSelector(
  selectAppState,
  (control) => control.fakeSubServices
)

export const basicInfo = createSelector(
  selectAppState,
  (control) => control.basicInfo
)

export const billingInfo = createSelector(
  selectAppState,
  (control) => control.billingInfo
)

export const datesInfo = createSelector(
  selectAppState,
  (control) => control.datesInfo
)

export const attachments = createSelector(
  selectAppState,
  (control) => control.attachments
)

export const selectedFakeSubService = createSelector(
  selectAppState,
  (control) => control.selectedFakeSubService
)
