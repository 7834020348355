import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AcceptInvitationComponent } from './pages/accept-invitation/accept-invitation.component'
import { SwitchLangModule } from '../../shared/switch-lang/switch-lang.module'
import { TranslateModule } from '@ngx-translate/core'
import { InputComponent } from '../../shared/input/input.component'
import { ReactiveFormsModule } from '@angular/forms'
import { SpinnerComponent } from '../../shared/spinner/spinner.component'
import { MatTooltipModule } from '@angular/material/tooltip'
import { SignUpFormComponent } from '../authentication/components/sign-up-form/sign-up-form.component'
import { BannerComponent } from '../authentication/components/banner/banner.component'
import { InvitationsRoutingModule } from './invitations-routing.module'

@NgModule({
  declarations: [AcceptInvitationComponent],
  imports: [
    CommonModule,
    SwitchLangModule,
    TranslateModule,
    InputComponent,
    ReactiveFormsModule,
    MatTooltipModule,
    SpinnerComponent,
    BannerComponent,
    SignUpFormComponent,
    InvitationsRoutingModule
  ],
  exports: [AcceptInvitationComponent]
})
export class InvitationsModule {}
