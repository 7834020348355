import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastHelper } from '../helpers/toast'
import { IAM_ERRORS, SERVICES_ERRORS } from '../helpers/validation-errors'

@Injectable({
  providedIn: 'root'
})
export class ErrorsService {
  constructor(
    private toastHelper: ToastHelper,
    private translate: TranslateService
  ) {}

  authError(error: string) {
    return this.getAuthErrorKey(error) === null
      ? this.toastHelper.errorToast('', error)
      : this.toastHelper.errorToast(
          '',
          this.translate.instant('authErrors.' + this.getAuthErrorKey(error))
        )
  }

  serviceError(error: string) {
    const errorKey = this.getServiceErrorKey(error)
    if (errorKey === null) {
      return this.toastHelper.errorToast('', error)
    }
    return this.toastHelper.errorToast(
      '',
      this.translate.instant('servicesErrors.' + this.getServiceErrorKey(error))
    )
  }

  // eslint-disable-next-line class-methods-use-this
  getAuthErrorKey(message: string) {
    for (const [key, val] of Object.entries(IAM_ERRORS)) {
      if (typeof val === 'function') continue

      if (val === message) {
        return key
      }
    }
    return null
  }

  // eslint-disable-next-line class-methods-use-this
  getServiceErrorKey(message: string) {
    for (const [key, val] of Object.entries(SERVICES_ERRORS)) {
      if (typeof val === 'function') continue

      if (val === message) {
        return key
      }
    }
    return null
  }
}
